import { BaseService } from './baseService'
import { API_PATH } from '~/utility/constants/api-path'

export class IndexServices extends BaseService {
  // TODO
  async getTopTimeLimits() {
    const [a, b] = await Promise.all([
      this.api.get(API_PATH.ENTRY_DISCOUNT_TIME_LIMIT),
      this.api.get(API_PATH.ENTRY_SETTLEMENT_TIME_LIMIT),
    ])

    // TODO エラーハンドリング。現在は b.data => { errors: ['Not Found']} が返答されている。
    return {
      data: {
        entryDiscountTimeLimit: a?.data?.entry_discount_time_limit,
        entrySettlementTimeLimit: b?.data?.entry_settlement_time_limit,
        entryPaymentUrl: b?.data?.payment_url,
      },
    }
  }
}
